.pageHeader {
  padding: 5px 24px;
}

.pageHeaderContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  gap: 12px;
}

.userName{
  color: #fff;
}